import { React, Link, StatePool, useEffect } from "../../imports/imports-packege";
import NavigationStyle from "./NavigationStyle.module.scss";

const ActiveTabNav = StatePool.createState('app');

const Navigation = ({ userTab }) => {
    const [activeTab, setActiveTab] = StatePool.useState(ActiveTabNav);

    const handleActiveTab = (tabName) => {
        setActiveTab(tabName);
        userTab(tabName);
    }

    return (
        <div className={NavigationStyle.NavigationSection}>
            <div className={NavigationStyle.NavigationLinkContainer}>
                <ul className={NavigationStyle.NavigationLinkList}>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'app' ? NavigationStyle.active : ''}`}
                            onClick={() => handleActiveTab('app')}
                            to="/">
                            Пріла
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'parameters' ? NavigationStyle.active : ''}`}
                            to="/parameters"
                            onClick={() => handleActiveTab('parameters')}>
                            Параметри
                        </Link>
                    </li>
                    <li className={NavigationStyle.NavigationItemList}>
                        <Link
                            className={`${NavigationStyle.Link} ${activeTab === 'review' ? NavigationStyle.active : ''}`}
                            to="/reviews"
                            onClick={() => handleActiveTab('review')}>
                            Відгуки
                        </Link>
                    </li>
                    {/*<li className={NavigationStyle.NavigationItemList}>*/}
                    {/*    <Link*/}
                    {/*        className={`${NavigationStyle.Link} ${activeTab === 'offer' ? NavigationStyle.active : ''}`}*/}
                    {/*        to="/offer"*/}
                    {/*        onClick={() => handleActiveTab('offer')}>*/}
                    {/*        Оффер*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
