import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import  '../PWAPreviewStyle.css';
import DownloadIcon from "../img/icon/icon_download.png";
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

const InformationSection = () => {
    let {data} = useData();

    useEffect(() => {
    }, [data])
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <div className="info-app">
            <div className="info-app__content">
                <div className="info-app__item rating">
                    <div className="info-app__item-content">
                        <span id="rating-val">{data.appRatingPage === '' ? '4.7' : data.appRatingPage}</span>
                        <svg enableBackground="new 0 0 24 24" version="1.1" viewBox="0 0 24 24" xmlSpace="preserve"
                             xmlns="http://www.w3.org/2000/svg">
                            <g fill="none">
                                <path d="M0,0h24v24H0V0z"></path>
                                <path d="M0,0h24v24H0V0z"></path>
                            </g>
                            <path
                                d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z">
                                                                        </path>
                        </svg>
                    </div>
                    <p className="info-app__description">
                        {data.langCode.length > 0 ? ListTextSecurity['rating-text'] : 'Rating'}
                    </p>
                </div>
                <div className="info-app__item">
                    <div className="info-app__item-content">
                        <img src={DownloadIcon} className="info-app__icon-download" alt=""/>
                    </div>
                    <p className="info-app__description">
                        <span id="size-app">{data.appSize === '' ? '11,4' : data.appSize}</span>MB
                    </p>
                </div>
                <div className="info-app__item">
                    <div className="info-app__item-content age-content">
                        <span id="age-rating">{data.age === '' ? '18' : data.age}</span>
                        <span>+</span>
                    </div>
                    <p className="info-app__description">
                        {data.langCode.length > 0 ? ListTextSecurity['text-age'] : 'Age'}
                    </p>
                </div>
                <div className="info-app__item">
                    <div className="info-app__item-content installed">
                        <span id="count-install">{data.amountInstall === '' ? '37436' : data.amountInstall}</span>
                    </div>
                    <div className="info-app__description" id="installed-translate">
                        {data.langCode.length > 0 ? ListTextSecurity['installed-translate'] : 'Installed'}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InformationSection