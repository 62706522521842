import {React} from "../../../imports/imports-packege";
import ButtonStyle from "./ButtonStyle.module.scss";

const Button = ({ButtonText}) => {
    return (
        <div className={ButtonStyle.CustomButton}>
            <p className={ButtonStyle.CustomButton_Text}>{ButtonText}</p>
        </div>
    );
};

export default Button;