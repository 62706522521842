import {React} from "../../../imports/imports-packege";
import ActionTitleStyle from "./ActionTitleStyle.module.scss";
const ActionTitle = ({actionText}) => {
    return (
        <>
            <p className={ActionTitleStyle.ActionTitle}>{actionText}</p>
        </>
    );
};

export default ActionTitle;