import {React} from '../../imports/imports-packege';
import HeaderStyle from './HeaderStyle.module.scss';

const LanguagesSelect = () => {
    return (
        <div className={HeaderStyle.LanguagesSelectContainer}>
            <ul className={HeaderStyle.LanguagesList}>
                <li className={HeaderStyle.LanguagesList_Item}>UA</li>
                <li className={HeaderStyle.LanguagesList_Item}>EN</li>
            </ul>
        </div>
    );
};

export default LanguagesSelect;