import React, { useState } from "../../../imports/imports-packege";
import {ActionTitle, SelectList, PopoverInfo, Input, Button} from "../../../imports/imports-components";
import OfferStyle from "./OfferStyle.module.scss";
import SubItemContent from "./SubItemContent";

const Offer = () => {
    const [subItems, setSubItems] = useState([<SubItemContent key={0} />]);

    const addSubItem = () => {
        const newItem = <SubItemContent key={subItems.length} />;
        setSubItems(prevSubItems => [...prevSubItems, newItem]);
    };
    const removeSubItem = () => {
        if (subItems.length > 0) {
            setSubItems(prevSubItems => prevSubItems.slice(0, -1)); // Видаляємо останній елемент з масиву підкомпонентів
        }
    };


    return (
        <div className={OfferStyle.Container}>
            <div className={OfferStyle.OfferLink}>
                <ActionTitle actionText={"Посилання на оффер"} />
                <Input />
            </div>

            <div className={OfferStyle.SubItemList}>
                <ActionTitle actionText={"Додайте необхідні параметри"}/>
                <div className={OfferStyle.SubItemList__ButtonAction}>
                    <div onClick={addSubItem} className={OfferStyle.SubItemList__Button}>+</div>
                    <div onClick={removeSubItem} className={OfferStyle.SubItemList__Button}>-</div> {/* Додаємо кнопку "-", яка викликає функцію видалення останнього елемента */}

                </div>

                <div className={OfferStyle.SubItemList__Content}>
                    {subItems}
                </div>
            </div>
        </div>
    );
}

export default Offer;
