import {React, useState, queryString, useEffect, StatePool, axios} from "../../../imports/imports-packege";
import {ActionTitle, SelectList, PopoverInfo, Input, Button} from "../../../imports/imports-components";
import { UploadIconNew, SuccessUpload} from "../../../imports/imports-images";
import { useData } from "../../Context/ContextApp";
import AppDesignStyle from "./AppDesignStyle.module.scss";
import { ListOptionLanguageEnVersion } from "../../SelectOption/SelectOption";
import '../../../assets/style/AppStyle.css';

const NameApp = StatePool.createState('');

const ParseLink = StatePool.createState(undefined);

const AppDesign = () => {
    const { data, updateData } = useData();
    const [appName, setAppName] = StatePool.useState(NameApp);

    const [previewUrl, setPreviewUrl] = useState(null);



    const handlerAppName = (query_appName) => setAppName(query_appName);
    useEffect((app_name)=>{
        if (app_name) {
            handlerAppName(app_name);
        }
        updateData({
            ...data,
            landName: appName
        })
    }, [appName])
    const [iconUploadStatus, setIconUploadStatus] = useState(false); // Стан, що вказує, чи завантажено іконку

    const handleIconUrl = (event) => {
        const file = event.target.files[0];
        handleFileChange({ target: { files: [file] } });
        if (file) {
            const previewIcon = new FileReader();
            previewIcon.onload = () => {
                setPreviewUrl(previewIcon.result);
                setIconUploadStatus(true);
            }

            previewIcon.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const [iconBaseCode, setIconBaseCode] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const fileObject = new File([file], 'icon_resize.png', { type: file.type });
                setIconBaseCode(base64String);
                updateData({
                    ...data,
                    iconUrl: base64String,
                    iconFile:  fileObject
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const [previewScreens, setPreviewScreens] = useState([null, null, null, null]);
    const [screenshotUploadStatus, setScreenshotUploadStatus] = useState([false, false, false, false]);

    const handleScreen_1 = (event) => {
        const file = event.target.files[0];
        handleScreen_1Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[0] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const handleScreen_2 = (event) => {
        const file = event.target.files[0];
        handleScreen_2Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[1] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }
    const handleScreen_3 = (event) => {
        const file = event.target.files[0];
        handleScreen_3Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[2] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }
    const handleScreen_4 = (event) => {
        const file = event.target.files[0];
        handleScreen_4Change({ target: { files: [file] } });
        if (file) {
            const previewScreen = new FileReader();
            previewScreen.onload = () => {
                const updatedScreens = [...previewScreens];
                updatedScreens[3] = previewScreen.result;
                setPreviewScreens(updatedScreens);
            }
            previewScreen.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
        }
    }

    const handleScreen_1Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name, { type: file.type });
                updateData({
                    ...data,
                    screenListFirst: fileObject,
                    screenshotImages: { ...data.screenshotImages, screenListFirst: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_2Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screenListSecond: fileObject,
                    screenshotImages: { ...data.screenshotImages, screenListSecond: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_3Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screenListThird: fileObject,
                    screenshotImages: { ...data.screenshotImages, screenListThird: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const handleScreen_4Change = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const fileObject = new File([file], file.name , { type: file.type });
                updateData({
                    ...data,
                    screenListFour: fileObject,
                    screenshotImages: { ...data.screenshotImages, screenListFour: imageUrl },
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const [selectedTranslate, setSelectedTranslate] = useState(data.langCode || '');
    useEffect(() => {
        updateData({ ...data, langCode: selectedTranslate });
    }, [selectedTranslate]);
    const handleSelectTranslate = (translate) => {
        setSelectedTranslate(translate);
    }

    function HideInputLoadImages () {
        let iconBlock = document.getElementById('icon_block');
        let screenBlock = document.getElementById('screen_block');
        iconBlock.classList.add(`${AppDesignStyle.Section__hide}`);
        screenBlock.classList.add(`${AppDesignStyle.Section__hide}`);
    }

    const [LinkParse, setLinkParse] = StatePool.useState(ParseLink);
    const handleLinkParse = (link) => setLinkParse(link);
    const keitaroId = data.keitaroId;
    const sendLinkParse = async () => {
        let url = 'https://pwa.affpro.app//api/parse-googleplay?link_parse=';
        // let url = 'http://localhost:8181/api/parse-googleplay?link_parse=';
        if (!LinkParse) {
            alert('Додайте посилання на гру');
            return;
        }
        await axios.get(`${url}${LinkParse}&keitaro_id=${keitaroId}`)
            .then((response) => {
                if (response.data.error) {
                    alert('Ой, схоже саме цей додаток не вийде поцупити з Play Market, \b спробуй, будь ласка, інший');
                    return false;
                }

                let iconLink = response.data.icon;
                let screensLink = response.data.screen;
                updateData({ ...data, iconUrl: iconLink, screenshotImages: screensLink, parseContents: true});
                HideInputLoadImages();
            });
    }

    useEffect(() => {
        if (LinkParse !== undefined) {
            HideInputLoadImages()
        }
    }, [LinkParse]);

    return (
        <div className={AppDesignStyle.TabContent}>
            <div className={AppDesignStyle.Section}>
                <ActionTitle actionText={"Name"} />
                <Input
                    className={AppDesignStyle.InputField}
                    inputValue={handlerAppName}
                    placeholder="App name"
                    valueSave={appName}
                />
            </div>
            <div className={`${AppDesignStyle.Section} ${AppDesignStyle.SelectList}`}>
                <ActionTitle actionText={"Language Pwa"}/>
                <SelectList
                    optionList={ListOptionLanguageEnVersion}
                    getSelectValue={handleSelectTranslate}
                    value={selectedTranslate}
                />
            </div>

            <div className={AppDesignStyle.Section}>
                <h2 className={AppDesignStyle.CustomTitle}>Copy app design from Google Play</h2>
                {/*<PopoverInfo*/}
                {/*    TitleText={"Link to the app on Google Play"}*/}
                {/*    DescriptionText={`Ви можете скопіювати дизайн будь-якого додатка з Google Play. Для цього просто введіть у це поле посилання на додаток у Play Market і натисніть "Скопіювати" `}*/}
                {/*/>*/}
                <Input
                    inputValue={handleLinkParse}
                    valueSave={LinkParse}
                    placeholder={"Посилання на гру Play Market"}/>

                <div onClick={sendLinkParse}>
                    <Button ButtonText={"Copy design"}/>
                </div>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="icon_block">
                <ActionTitle actionText={"Іконка"}/>

                <div className={AppDesignStyle.SelectIconBlock}>
                    <div className={`${AppDesignStyle.DownloadIconApp} ${iconUploadStatus ? AppDesignStyle.upload_success : ''}`}>
                        {!iconUploadStatus ? (
                            <>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleIconUrl}
                                    className={AppDesignStyle.DownloadIconApp_IconButtonDowload}
                                />
                                <img src={UploadIconNew} className={AppDesignStyle.DownloadIconApp_Icon}
                                     alt="Success Upload Icon"/>
                                <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                    <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>Від</span>
                                    <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>512x512px</span>
                                </p>
                            </>
                        ) : (
                            <img src={SuccessUpload} className={`${AppDesignStyle.DownloadIconApp_Icon} ${AppDesignStyle.DownloadIconApp_Icon_success}`}
                                 alt="Success Upload Icon"/>
                        )}

                    </div>
                    {/*{previewUrl && (*/}
                    {/*    <img src={previewUrl} className={AppDesignStyle.PreviewImage} alt="Preview" />*/}
                    {/*)}*/}
                </div>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="screen_block">
                <ActionTitle actionText={"Скріншоти"} />
                <div className={AppDesignStyle.ScreenshotList}>
                    {[1, 2, 3, 4].map(index => {
                        const handleScreen = (event, screenIndex) => {
                            switch (screenIndex) {
                                case 1:
                                    handleScreen_1(event);
                                    break;
                                case 2:
                                    handleScreen_2(event);
                                    break;
                                case 3:
                                    handleScreen_3(event);
                                    break;
                                case 4:
                                    handleScreen_4(event);
                                    break;
                                default:
                                    break;
                            }
                        };

                        return (
                            <div className={AppDesignStyle.ContainerItem} key={index}>
                                <div key={index} className={`${AppDesignStyle.DownloadIconApp} ${AppDesignStyle.ScreenItem}`}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => handleScreen(event, index)}
                                        className={AppDesignStyle.DownloadMultiScreen_MultiImageButtonDowload}
                                    />
                                    <img src={UploadIconNew} className={AppDesignStyle.DownloadIconApp_Icon} alt="Upload File Icon"/>
                                    <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                        <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>Від</span>
                                        <span className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>720x1280px</span>
                                    </p>
                                </div>
                                {/*{previewScreens[index - 1] && (*/}
                                {/*    <img key={index} src={previewScreens[index - 1]} className={AppDesignStyle.PreviewImage} alt="Preview" />*/}
                                {/*)}*/}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AppDesign;
