import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import Aviator from "../../../../assets/images/recommendedIcon/aviator.png"
import Leprechaun from "../../../../assets/images/recommendedIcon/Leprechaun.jpeg"
import LuckySpin from "../../../../assets/images/recommendedIcon/Lucky-spin-slot.jpeg"
import MagikCasino from "../../../../assets/images/recommendedIcon/magik-casino.png"
import PokerSlotSpin from "../../../../assets/images/recommendedIcon/Poker-slot-spin.jpeg"
import SweetBonanza from "../../../../assets/images/recommendedIcon/sweet-bonanza.png"
import '../PWAPreviewStyle.css';
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";
import GrayIconStar from "../img/icon/star-full-big.png";
import {useState} from "react";

const RecommendedAppSection = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    const ObjectDataIconApp= {
        'aviator': {
            'icon': Aviator,
            'name': 'Aviator'
        },
        'leprechaun': {
            'icon': Leprechaun,
            'name': 'Leprechaun'
        },
        'lucky-spin': {
            'icon': LuckySpin,
            'name': 'Lucky spin slot'
        },
        'magik-casino': {
            'icon': MagikCasino,
            'name': 'Magik casino'
        },
        'poker-slot': {
            'icon': PokerSlotSpin,
            'name': 'Poker slot spin'
        },
        'sweet-bonanza': {
            'icon': SweetBonanza,
            'name': 'Sweet bonanza'
        },
    }

    const [selectedApp, setSelectedApp] = useState([]);
    const [ImageSelectList, setImageSelectList] = useState([]);

    useEffect(() => {
        setSelectedApp(data.recommendedApp);
        const newList = data.recommendedApp.map(item => ObjectDataIconApp[item]);
        setImageSelectList(newList);
    }, [data.recommendedApp]);

    const randomNumber = (min, max) =>  {
        let randomNumber = Math.random() * (max - min) + min;
        return randomNumber.toFixed(2);
    }


    useEffect(() => {
    }, [data])

    return (
        <section className="recommended-app">
            <h1 className="recommended-app__title-block">
                <span>{data.author === '' ? ' PlayDev' : data.author}</span>:
                <span>{ data.langCode.length > 0 ? ListTextSecurity['translate-recommended'] : 'Other application'}</span>
            </h1>

            <div className="recommended-app__list-app">
                {
                    ImageSelectList.map((elem, key) => (
                            <div key={key} className="recommended-app__item">
                                <div
                                    className="recommended-app__logo">
                                    <img src={elem['icon']} alt="Screen"/>
                                </div>
                                <p className="recommended-app__name-app">{elem['name']}</p>

                                <p className="recommended-app__rating-app">
                                    {randomNumber(4.3, 5)}
                                    <img src={GrayIconStar} alt=""/>
                                </p>
                            </div>
                        )
                    )
                }
            </div>
        </section>
    )
}

export default RecommendedAppSection;