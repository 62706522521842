import { React, useState, useEffect } from "../../../imports/imports-packege";
import AppReviewStyle from "./AppReviewStyle.module.scss";
import { ActionTitle, SelectList } from "../../../imports/imports-components";
import { CountReviews } from "../../SelectOption/SelectOption";
import IconDownload from './upload-arrow-svgrepo-com.png';
import { useData } from "../../Context/ContextApp";
import CommentArrow from "./downward-arrow.png";

const AppReview = () => {
    const { data, updateData } = useData();

    const [countReviewSelect, setCountReviewSelect] = useState('');
    const [reviews, setReviews] = useState([]);

    const handleCountReview = (count) => {
        setCountReviewSelect(count);
        const newReviews = Array.from({ length: count }, (_, index) => data.reviews_data?.[index] || { show: false });
        setReviews(newReviews);
        updateData({
            ...data,
            countReviewShow: count,
            reviews_data: newReviews
        });
    };

    useEffect(() => {
        setCountReviewSelect(data.countReviewShow || 0);
        setReviews(Array.from({ length: data.countReviewShow || 0 }, (_, index) => data.reviews_data?.[index] || { show: false }));
    }, [data.countReviewShow, data.reviews_data]);

    const toggleComment = (index) => {
        const updatedReviews = [...reviews];
        updatedReviews[index].show = !updatedReviews[index].show;
        setReviews(updatedReviews);
    };

    const handleChange = (index, field, value) => {
        const updatedReviews = [...reviews];
        updatedReviews[index][field] = value;
        setReviews(updatedReviews);
        updateData({
            ...data,
            reviews_data: updatedReviews
        });
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const updatedReviews = [...reviews];
            updatedReviews[index].icon = reader.result;
            setReviews(updatedReviews);
            updateData({
                ...data,
                reviews_data: updatedReviews
            });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={AppReviewStyle.TabContent}>
            <div className={AppReviewStyle.CountReview}>
                <ActionTitle actionText={"Count comments"} />
                <div>
                    <SelectList
                        optionList={CountReviews}
                        getSelectValue={handleCountReview}
                        value={countReviewSelect}
                    />
                </div>
            </div>
            <div>
                {countReviewSelect > 0 &&
                    Array.from({ length: countReviewSelect }, (_, index) => (
                        <div className={AppReviewStyle.ItemComment} key={index}>
                            <div
                                className={AppReviewStyle.CommentTitleShow}
                                onClick={() => toggleComment(index)}>
                                Коментар {index + 1}
                                <img src={CommentArrow} className={AppReviewStyle.ArrowCommentTitle} alt="" />
                            </div>
                            {reviews[index]?.show && (
                                <div className={AppReviewStyle.CommentInfoContainer}>
                                    <div className={AppReviewStyle.ContainerShortInfoCommentator}>
                                        <div className={AppReviewStyle.LogoReview}>
                                            <p className={AppReviewStyle.ResponseField}>Іконка коментатора</p>
                                            <div className={AppReviewStyle.DownloadIconApp_Container}>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(index, e)}
                                                    className={AppReviewStyle.DownloadIconApp_IconButtonDownload}
                                                />
                                                <img src={IconDownload} className={AppReviewStyle.Icon_Logo_download} alt=""/>
                                                <span>Select user logo</span>
                                            </div>

                                        </div>
                                        <div className={AppReviewStyle.CommentatorName}>
                                            <p className={AppReviewStyle.ResponseField}>Імʼя коментатора</p>
                                            <input
                                                type="text"
                                                value={reviews[index]?.name || ''}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                                placeholder="User487657"
                                            />
                                        </div>
                                        <div className={AppReviewStyle.CommentatorRating}>
                                            <p className={AppReviewStyle.ResponseField}>Рейтинг коментаря</p>
                                            <input
                                                type="number"
                                                value={reviews[index]?.rating || ''}
                                                onChange={(e) => handleChange(index, 'rating', e.target.value)}
                                                placeholder="4.7"
                                            />
                                        </div>
                                        <div className={AppReviewStyle.CommentDate}>
                                            <p className={AppReviewStyle.ResponseField}>Дата коментаря</p>
                                            <input
                                                type="text"
                                                value={reviews[index]?.date || ''}
                                                onChange={(e) => handleChange(index, 'date', e.target.value)}
                                                placeholder="27.04.2024"
                                            />
                                        </div>
                                    </div>
                                    <div className={AppReviewStyle.TextComment}>
                                        <p className={AppReviewStyle.ResponseField}>Текст Коментаря</p>
                                        <textarea
                                            value={reviews[index]?.commentText || ''}
                                            onChange={(e) => handleChange(index, 'commentText', e.target.value)}
                                            rows="8"
                                            placeholder="Your text comment"
                                        />
                                    </div>
                                    <div className={AppReviewStyle.DeveloperResponse}>
                                        <p className={AppReviewStyle.ResponseField}>
                                            <span>Відповідь на коментар</span>
                                            <span>*Якщо поле пусте, воно не зʼявиться на сторінці</span>
                                        </p>
                                        <textarea
                                            value={reviews[index]?.dev_response || ''}
                                            onChange={(e) => handleChange(index, 'dev_response', e.target.value)}
                                            rows="8"
                                            placeholder="Developer response"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default AppReview;
