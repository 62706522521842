import {React, $} from "../../../imports/imports-packege";
import PWAPreviewStyle from './PWAPreviewStyle.css';
import AboutSection from "./AppComponent/AboutSection";
import  './PWAPreviewStyle.css';
import InformationSection from "./AppComponent/InformationSection";
import ButtonSection from "./AppComponent/ButtonSection";
import GallerySection from "./AppComponent/GallerySection";
import DescriptionTextSection from "./AppComponent/DescriptionTextSection";
import ListCategories from "./AppComponent/ListCategories";
import DataSecureSection from "./AppComponent/DataSecureSection";
import RatingSection from "./AppComponent/RatingSection";
import ReviewSection from "./AppComponent/ReviewSection";
import RecommendedAppSection from "./AppComponent/RecommendedAppSection";


const PWAPreview = () => {
    return (
        <div className="pwa_container">
            <div className="pwa-content">
                <AboutSection />
                <InformationSection />
                <ButtonSection />
                <GallerySection />
                <DescriptionTextSection />
                <ListCategories />
                <DataSecureSection />
                <RatingSection />
                <ReviewSection />
                <RecommendedAppSection />
            </div>
        </div>
    );
};

export default PWAPreview;