import { React, useEffect } from '../../../../imports/imports-packege';
import '../PWAPreviewStyle.css';
import { useData } from "../../../Context/ContextApp";
import StarBlue from "../img/icon/icon-full-big-blue.png";
import StarGray from "../img/icon/star-full-big.png";
import LogoDefault from "../img/icon/alien.png";

const ReviewSection = () => {
    const { data } = useData();
    const { reviews_data = [] } = data;

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(<img key={i} src={i < rating ? StarBlue : StarGray} alt="star" />);
        }
        return stars;
    };

    return (
        <div className="reviews">
            <div className="reviews__container">
                {reviews_data.map((review, index) => (
                    <div className="reviews__item" key={index}>
                        <div className="reviews__top-info">
                            <div className="reviews__logo-container">
                                <img src={review.icon || LogoDefault} alt="reviewer icon" />
                            </div>
                            <div className="reviews__name-reviewer">
                                {review.name || "Anonymous"}
                            </div>
                        </div>
                        <div className="reviews__rating_date">
                            <div className="reviews__rating">
                                {renderStars(review.rating || 5)}
                            </div>
                            <div className="reviews__date">
                                {review.date || "Unknown date"}
                            </div>
                        </div>
                        <div className="reviews__text-comment">
                            {review.commentText || "No comment provided."}
                        </div>
                        {review.dev_response && (
                            <div className="reviews__developer-response">
                                <div className="reviews__name-response">
                                    <span className="name-response">Developer Response</span>
                                    <p className="text">
                                        {review.dev_response}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ReviewSection;
