import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import  '../PWAPreviewStyle.css';
import ScreenDefault_1 from "../img/67.1.jpg";
import ScreenDefault_2 from "../img/67.2.jpg";
import ScreenDefault_3 from "../img/67.3.jpg";
import ScreenDefault_4 from "../img/67.4.jpg";
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

const GallerySection = () => {
    let {data, updateData} = useData();
    useEffect(() => {
    }, [data])
    return (
        <div className="gallery">
            <div className="gallery__container">
                {
                    data.screenshotImages.length === 0 ?
                        <div className="gallery__item" >
                            <img src={ScreenDefault_1} alt="" />
                        </div> : <>
                            {Object.keys(data.screenshotImages).map((key, index) => (
                                <div key={index} className="gallery__item">
                                    <img src={data.screenshotImages[key]} alt=""  />
                                </div>
                            ))}
                        </>
                }
            </div>
        </div>
    )
}

export default GallerySection