import {SendIcon} from '../../imports/imports-images';
import {React, StatePool, useEffect, axios, queryString, useState} from "../../imports/imports-packege";
import {useData} from "../Context/ContextApp";
import PreviewStyle from "./PreviewStyle.module.scss";
import PWAPreview from "./PWAPreview/PWApreview";
import App from "../../App";

const Preview = () => {
    const { data, updateData } = useData();

    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }
    useEffect(() => {
        handleTranslate(translateQueryParameter)
    }, [translateQueryParameter])

    // const [errorText, setErrorText] = useState();
    // const handleErrorText = (error) => setErrorText(error);

    const [landName, setLandName] = useState('');
    const [oneSignalId, setOneSignalId] = useState('');
    const [keitaroId, setKeitaroId] = useState('');
    const [builderMode, setBuilderMode] = useState('create');
    const [buttonText, setButtonText] = useState('Send');

    const handleLandName = (name) => setLandName(name);
    useEffect(() => {
        handleLandName(data.landName);
    }, [data.landName]);


    const handleButtonText = (text) => setButtonText(text);
    const handlerOneSignalId = (query_appId) => setOneSignalId(query_appId);
    const handlerKeitaroId = (query_keitaroId) => setKeitaroId(query_keitaroId);
    const handleBuilderMode = (mode) => setBuilderMode(mode);

    const [landingType, setLandingType] = useState('');
    const handleLandingType = (type_land) => setLandingType(type_land);

    const currentUrl = window.location.href;
    const parsedParams = queryString.parseUrl(currentUrl);
    const keitaro_id = parsedParams.query.id_land;
    const onesignal_id = parsedParams.query.id_onesignal;
    const type_landing = parsedParams.query.type_land;
    const builder_mode = parsedParams.query.mode;

    const [responseMessage, setResponseMessage] = useState(undefined);
    const handleResponseMessage = (message) => setResponseMessage(message)
    useEffect(() => {
        if (responseMessage === 'success') {
            // window.location.href = 'tg://resolve?domain=easy_links_bot'
        }
        if (responseMessage === 'error') {
            alert('Помилка при відправці в кейтаро \b Перейдіть заново по посиланню в ТГ ');
        }
    }, [handleResponseMessage]);

    useEffect(() => {
        handlerKeitaroId(!keitaro_id ? data.keitaroId : keitaro_id);
        handlerOneSignalId(!onesignal_id ? data.oneSignalId : onesignal_id);
        handleBuilderMode(builder_mode);
        handleLandingType(type_landing);
        updateData({
            ...data,
            builderMode: builderMode,
            oneSignalId: oneSignalId,
            keitaroId: keitaroId,
            landingType: landingType
        })
    }, [keitaroId, onesignal_id, type_landing, builder_mode]);

    var dataSend = {};
    useEffect(() => {
        dataSend = {
            landingType: data.landingType,
            builderMode: data.builderMode,
            languageBilder: data.languageBilder,
            keitaroId: data.keitaroId,
            oneSignalId: data.oneSignalId,
            landName: data.landName,
            langCode: data.langCode,
            iconUrl: data.iconUrl,
            countComments: data.countComments,
            screenshotImages: data.screenshotImages,
            author: data.author,
            categories: data.categories,
            age: data.age,
            appSize: data.appSize,
            amountInstall: data.amountInstall,
            buttonText: data.buttonText,
            landDescriptionTitle: data.landDescriptionTitle,
            landDescriptionText: data.landDescriptionText,
            multiCategories: data.multiCategories,
            recommendedApp: data.recommendedApp,
            reviews_data: data.reviews_data,
            parseContents: data.parseContents,
            progressBars: data.progressBars
        }

    }, [data, builder_mode, dataSend]);

    useEffect(() => {
        // console.log(data, 'data');
    }, [data]);

    function SendDataPwa() {
        const sendData = async () => {
            try {
                // const url = 'http://localhost:8181/api/create-pwa';
                const url = 'https://pwa.affpro.app/api/create-pwa';
                const response = await axios.post(url, dataSend);
                handleResponseMessage(response.data);
            } catch (error) {
                console.log('Error:', error);
            } finally {
                handleButtonText('Готово');
            }
        };
        sendData();
    }

    return (
        <div className={PreviewStyle.PreviewContainer}>
            <div className={PreviewStyle.HeaderPreview}>
                <div className={PreviewStyle.InfoDownload}>
                    <div className={PreviewStyle.InfoDownload_SendLand} onClick={SendDataPwa}>
                        <div className={PreviewStyle.InfoDownload_SendLand_Button}>
                            <img src={SendIcon} className={PreviewStyle.IconSend} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <PWAPreview/>
        </div>
    );
};

export default Preview;