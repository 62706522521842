import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import  '../PWAPreviewStyle.css';
import PreviewIcon from "../img/rec_1.png";
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

const AboutSection = () => {
    let {data} = useData();

    useEffect(() => {
    }, [data])

    return (
        <div className="about">
            <div className="about__icon">
                <img src={data.iconUrl === '' ? PreviewIcon : data.iconUrl} className="about__icon-image" id="icon_pwa" alt=""/>
            </div>
            <div className="about__text-info">
                <div className="about__name-pwa" id="name-app">{data.landName === '' ? 'Long name app google' : data.landName}</div>
                <div className="about__info-developer">
                    <div className="about__developer-name">{data.author === '' ? 'PlayMarket' : data.author}</div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection