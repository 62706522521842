import React, { useState, useEffect } from 'react';
import { useData } from "../../Context/ContextApp";
import './ProgressBarStyle.css';

const ProgressBar = ({ index }) => {
    const { data, updateData } = useData();
    const [progress, setProgress] = useState(data.progressBars[index] || 0);

    const handleSliderChange = (event) => {
        const newValue = event.target.value;
        setProgress(newValue);

        const newProgressBars = [...data.progressBars];
        newProgressBars[index] = newValue;

        updateData({
            ...data,
            progressBars: newProgressBars
        });
    };

    useEffect(() => {
        setProgress(data.progressBars[index] || 0);
    }, [data.progressBars, index]);

    return (
        <div className="progress-bar-container">
            <div className="progress-bar-item">
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={progress}
                    onChange={handleSliderChange}
                    className="slider"
                />
            </div>
        </div>
    );
};

export default ProgressBar;
