import {React, BrowserRouter, Route, Routes} from "../../imports/imports-packege";
import {AppDesign, AppParameters, AppReview, OfferPage} from "../../imports/imports-components";

const EditContentTabs = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<AppDesign />} />
                <Route path="/parameters" element={<AppParameters />} />
                <Route path="/reviews" element={<AppReview />} />
                <Route path="/offer" element={<OfferPage />} />
            </Routes>
        </>
    );
};

export default EditContentTabs;