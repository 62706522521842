import './assets/style/AppStyle.css';
import {MainContentSection, Header} from "./imports/imports-components";
import React, { useState, useEffect } from 'react';
import {DataProvider} from './components/Context/ContextApp';

function App() {
  return (
    <div className="MainFullScreen">
        <DataProvider>
            <Header />
            <MainContentSection />
        </DataProvider>
    </div>
  );
}

export default App;
