import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import '../PWAPreviewStyle.css';
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";
import ShareIcon from "../img/icon/icon_share.svg";
import ShareLock from "../img/icon/icon_lock.svg";

const DataSecureSection = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];


    return (
        <>
            <div className="data_security">
                <h1 id="dataSecurity_text">{data.langCode.length > 0 ? ListTextSecurity['dataSecurity_text'] : 'Security  data'}</h1>
                <div className="data_security__content">
                    <p>
                        <img src={ShareIcon} alt=""/>
                        <span id="data_save_1">
                            {data.langCode.length > 0 ? ListTextSecurity['secure-text-share'] : 'This application does not share data with third parties'}
                        </span>
                    </p>
                    <p>
                        <img src={ShareLock} alt=""/>
                        <span id="data_save_2">
                            {data.langCode.length > 0 ? ListTextSecurity['secure-text-lock'] : 'Data is encrypted during transmission'}
                        </span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default DataSecureSection;