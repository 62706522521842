import {React} from '../../../imports/imports-packege';
import OfferStyle from "./OfferStyle.module.scss";
import {Input} from "../../../imports/imports-components";

const SubItemContent = () => {
    return (
        <>
            <div className={OfferStyle.SubItemList__Item}>
                <div className={OfferStyle.SubItemList__InputsContainer}>
                    <Input placeholder={"sub name"}/>
                    <Input placeholder={"value sub"}/>
                </div>
            </div>
        </>
    )
}

export default SubItemContent;