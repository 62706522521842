import {React, useEffect, useState} from "../../../imports/imports-packege";
import TextAriaStyle from "./TextAriaStyle.module.scss";

const TextAria = ({valueField, placeholderText, valueSave}) => {
    const [value, setValue] = useState();
    const handleSetValue = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        valueField(newValue);
    }
    useEffect(() => {
    }, [value]);
    return (
        <>
            <textarea
                className={TextAriaStyle.TextAriaField}
                cols="100%"
                rows={8}
                onChange={handleSetValue}
                defaultValue={valueSave !== '' ? valueSave : ''}
                placeholder={placeholderText} />
        </>
    );
};

export default TextAria;