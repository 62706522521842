import {React, BrowserRouter, StatePool, useEffect, axios} from '../../imports/imports-packege';
import MainContentSectionStyle from './MainContentSectionStyle.module.scss';
import {Navigation, Preview} from "../../imports/imports-components";
import NavigationStyle from "../Navigation/NavigationStyle.module.scss";
import EditContentTabs from "../Navigation/EditContentTabs";
import {useData} from "../Context/ContextApp";


const ActiveTab = StatePool.createState('');
const MainContentSection = () => {

    const { data, updateData } = useData();
    let getUserQuery = new URLSearchParams(window.location.search);
    let getQueryTypeCreate = getUserQuery.get('mode');
    let getQueryKeitaroId = getUserQuery.get('id_land');

    async function getConfigFile (keitaro_id) {
        try {
            // let response = await axios.get(`http://localhost:8181/api/get-config?keitaro_id=${keitaro_id}`);
            let response = await axios.get(`https:///api/get-config?keitaro_id=${keitaro_id}`);
            console.log(response.data, 'configData')
            // return response.data;
            return JSON.parse(response.data.data);
        } catch (error) {
            console.log(`Error get config ${keitaro_id}: ${error}`)
        }
    }

    useEffect(() => {
        if (getQueryTypeCreate === 'edit') {
            getConfigFile(getQueryKeitaroId)
                .then((configData) => {
                    updateData(configData);
                    updateData({
                        parseContents: false
                    })
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    }, [getQueryTypeCreate, getQueryKeitaroId]);


    const [activeTab, setActiveTab] = StatePool.useState(ActiveTab);
    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    }
    useEffect(() => {
    }, [activeTab]);

    const renderPreview = () => {
        if (activeTab === 'offer') {
            return null;
        }
        return <Preview />;
    };
    return (
        <div className={MainContentSectionStyle.MainSection}>
            {/*<DataProvider>*/}
                    <BrowserRouter>
                        <Navigation userTab={handleActiveTab}/>
                        <div className={NavigationStyle.TabsEditContent}>
                            <EditContentTabs/>
                        </div>
                    </BrowserRouter>
                    <div className={MainContentSectionStyle.PreviewSection}>
                        {renderPreview()}
                        {/*<Preview/>*/}
                    </div>
            {/*</DataProvider>*/}
        </div>
    );
};

export default MainContentSection;

