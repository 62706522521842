import { React, useEffect, useState } from '../../../../imports/imports-packege';
import '../PWAPreviewStyle.css';
import { useData } from "../../../Context/ContextApp";
import TranslateCategories from '../Translate/translate-categories-app.json';

const ListCategories = () => {
    const { data } = useData();
    const [selectedCategories, setSelectCategories] = useState([]);
    const [translateCategories, setTranslateCategories] = useState([]);

    const handleSelectedCategories = (arrayCategories) => {
        let valueCategories = arrayCategories.map(item => item.value);
        setSelectCategories(valueCategories);
    };

    const handleTranslateCategories = (lang, categories = []) => {
        let objectTranslateCategories = TranslateCategories[lang];
        let updatedTranslateCategories = categories.map(element => objectTranslateCategories[element]);
        setTranslateCategories(updatedTranslateCategories);
    };

    useEffect(() => {
        if (data.multiCategories.length === 0) {
            setTranslateCategories([]);
        } else if (data.multiCategories.length !== 0 && data.langCode !== '') {
            const newSelectedCategories = [...data.multiCategories];
            handleSelectedCategories(newSelectedCategories);
            handleTranslateCategories(data.langCode, newSelectedCategories);
        }
    }, [data.multiCategories, data.langCode]);

    return (
        <div className="list-categories">
            {translateCategories.length > 0 ? (
                <div className="list-categories-container">
                    {translateCategories.map((element, index) => (
                        <div key={index} className="list-categories__item">{element}</div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}

export default ListCategories;
